// src/pages/_app.tsx
import "../styles/globals.css";
import type { AppType } from "next/app";
import { ClerkProvider } from "@clerk/nextjs";

import { trpc } from "@30p/ui/utils/trpc.web";
import { AnimatePresence, motion } from "motion/react";
import { Provider, createStore } from "jotai";

const myStore = createStore();

import { Inter } from "next/font/google";
import clsx from "clsx";
import { Theme } from "@30p/ui/utils/Theme";

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
  subsets: ["latin"],
  display: "swap",
});

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <ClerkProvider
      afterSignInUrl="/dashboard"
      // signUpUrl="/sign-up"
      // localization={{
      //   footerPageLink__privacy:
      //     "By signing up you accept our terms and conditions",
      // }}
      // appearance={{
      //   layout: {
      //     termsPageUrl: "/terms",
      //     privacyPageUrl: "/privacy",
      //   },
      // }}
    >
      <Provider store={myStore}>
        <div className={clsx("flex h-full flex-col", inter.className)}>
          <Theme>
            <div id="modal-root" />
            <AnimatePresence mode="wait">
              <motion.div className="h-full text-text" key={Component.name}>
                <Component {...pageProps} />
              </motion.div>
            </AnimatePresence>
          </Theme>
        </div>
      </Provider>
    </ClerkProvider>
  );
};

export default trpc.withTRPC(MyApp);
